@import '../../../styles/abstracts/variables';

.container-inspection-scheduled-confirm {
  .row {
    .title-inspection-scheduled-confirm {
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    .title-inspection-scheduled-confirm > img {
      float: left;
    }

    .title-inspection-scheduled-confirm > h1 {
      font-size: 16px;
      color: $purple;
      padding-left: 4.5rem;
    }

    .title-inspection-scheduled-confirm > h1 > strong {
      font-size: 30px;
    }

    .thanks-inspection-shceduled-confirm {
      text-align: center;
      font-size: 19px;
      margin-bottom: 2rem;
      line-height: 1.8rem;
      color: $purple;
    }

    .image-confirm-inspection-scheduled {
      display: block;
      margin: auto;
    }

    .send-info-inspection-scheduled-confirm {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }

    .info-confirm-inspection-scheduled-confirm {
      margin-top: 1.5rem;
      font-size: 16px;
      text-align: center;
      color: #323744;
    }

    .horizontal-line-inspection-scheduled-confirm {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      height: 1px;
      background-color: #dadada;
      margin-top: 2rem;
    }

    .button-confirm-agend-inspection-scheduled-confirm {
      margin-top: 2.5rem;
      margin-bottom: 10rem;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    .confirm-ins-sp {
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .container-inspection-scheduled-confirm {
    .row {
      .info-confirm-inspection-scheduled-confirm {
        text-align: right;
      }
    }
  }
}
