@import '../../styles/abstracts/variables';

.title-inspection-confirm {
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.title-inspection-confirm > img {
  float: left;
}

.title-inspection-confirm > h1 {
  font-size: 1.2em;
  padding-top: 1rem;
}

.info-visit-agend-p {
  text-align: center;
  font-size: 1.6em;
  margin-bottom: 2rem;
  line-height: 1.8rem;
}

.image-confirm-inspection {
  display: block;
  margin: auto;
}

.info-confirm {
  margin-top: 1.5rem;
  font-size: 1.3em;
  text-align: center;
  color: $black-text;
}

.horizontal-line {
  width: 100%;
  height: 1px;
  background-color: $white-light;
  margin-top: 2rem;
}

.button-confirm-agend {
  margin-top: 2.5rem;
  margin-bottom: 10rem;
}

.confirm-ins-sp {
  font-weight: bold;
}
